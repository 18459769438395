import React, { useState, useEffect, useLayoutEffect } from "react";
import Modal from "../../components/Modal";
import UserForm from "./UserForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import {
  getUsers,
  addUser,
  updateUser,
  deleteUser,
} from "../../Auth/services/Authapi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const userData = await getUsers(user.orgId);
      const filteredUsers = Array.isArray(userData.data.data)
        ? userData.data.data.filter((user) => !user.isAdmin)
        : [];
      setUsers(filteredUsers);
    } catch (error) {
      toast.error("An error occurred while fetching the user data");
      console.error(error);
    }
  };

  const addNewUser = (user = null) => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleSaveUser = async (user) => {
    console.log(user)
    try {
      if (user.userId) {
        await updateUser(user);
        toast.success("User updated successfully");
      } else {
        user.orgId = user.orgId;
        await addUser(user);
        toast.success("User added successfully");
      }
    } catch (error) {
      toast.error("An error occurred while saving the user data");
      console.error(error);
    }
    fetchUsers();
    handleCloseModal();
  };

  const handleEdit = (user) => {
    setIsModalOpen(true);
    setSelectedUser(user);
  };

  const handleDelete = async (userId) => {
    try {
      await deleteUser(userId);
      const updatedUsers = users.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
      toast.success("User deleted successfully");
      fetchUsers();
    } catch (error) {
      toast.error("An error occurred while deleting the user");
    }
  };

  return (
    <div className="m-2">
      <div className="flex justify-between items-center mb-4">
        <div className="font-bold text-xl ml-3">All Users</div>
        <button
          onClick={() => addNewUser()}
          className="w-30 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          <FontAwesomeIcon icon={faUserPlus} className="mr-2" /> Add User
        </button>
      </div>
      <table className="table-auto w-full border-collapse mt-5">
        <thead>
          <tr className="table-header-bg">
            <th className="px-4 py-2">User Name</th>
            <th className="px-4 py-2">User Email</th>
            <th className="px-4 py-2">Phone Number</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.length === 0 ? (
            <tr>
              <td colSpan="7" className="text-center py-2 no-records">
                No users are created yet
              </td>
            </tr>
          ) : (
            users?.map((user) => (
              <tr key={user.userId} className="border-b">
                <td className="px-4 py-2 text-center">
                  {user.username || "NA"}
                </td>
                <td className="px-4 py-2 text-center">{user.email}</td>
                <td className="px-4 py-2 text-center">{user.phoneNumber1}</td>
                <td className="px-4 py-2 text-center flex justify-center">
                  <div className="icon-buttons">
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "#00bfff",
                      }}
                      onClick={() => handleEdit(user)}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        color: "red",
                      }}
                      onClick={() => handleDelete(user.userId)}
                    />
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      <Modal
        isOpen={isModalOpen}
        header={<h1>Add/Update User</h1>}
        onRequestClose={handleCloseModal}
      >
        <UserForm
          onClose={handleCloseModal}
          onSave={handleSaveUser}
          initialData={selectedUser}
          userData={user}
          isEditMode={!!selectedUser}
        />
      </Modal>
    </div>
  );
};

export default ManageUsers;
