import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import {
  getOrganizationTypes,
  checkEmailExists,
} from "../../Auth/services/Authapi";
import { toast } from "react-toastify";
import "./SignupForm.css";

const SignupForm = ({ onSubmit }) => {
  const form = useForm();
  const { register, control, handleSubmit, watch, formState, reset } = form;
  const { errors } = formState;
  const [orgTypes, setOrgTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);


const handleFormSubmit = async (data) => {
  try {
    if (isSubmitted) return; // Prevent multiple submissions
    setIsSubmitted(true); // Set the state to true

    const emailExists = await checkEmailExists({ email: data.email });
    if (emailExists?.data?.data) {
      toast.error(
        "This email is already registered. Please choose another one."
      );
      setIsSubmitted(false); // Reset the state if email exists
      return;
    }

    const payload = transformDataToPayload(data);
    await onSubmit(payload);
    toast.success("Signup successful!");
    reset();
  } catch (error) {
    toast.error("An error occurred during signup. Please try again.");
    console.error("Signup error:", error);
  } finally {
    setIsSubmitted(false); // Reset the state in finally block
  }
};

  useEffect(() => {
    getOrganizationTypes()
      .then((res) => {
        setOrgTypes(res.data.data);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  const selectedOrgTypeId = watch("orgTypeId");

  const transformDataToPayload = (data) => {
    const {
      orgId,
      name,
      address,
      city,
      state,
      pincode,
      orgTypeId,
      ...userInfo
    } = data;
    return {
      orgTypeId: Number(selectedOrgTypeId),
      name,
      address,
      city,
      state,
      pincode,
      userInfo: {
        ...userInfo,
        middleName: userInfo.middleName || "",
        phoneNumber2: userInfo.phoneNumber2 || "",
        isAdmin: true,
      },
    };
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
      <div className="signup-form-grid">
        <div className="form-field">
          <label htmlFor="firstName" className="block text-gray-700">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            className="w-full border border-gray-300 rounded-md"
            {...register("firstName", {
              required: "First name is required",
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "First name can only contain alphabets",
              },
              maxLength: {
                value: 20,
                message: "First name cannot exceed 20 characters",
              },
            })}
          />
          <p className="error">{errors.firstName?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="lastName" className="block text-gray-700">
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="lastName"
            className="w-full border border-gray-300 rounded-md"
            {...register("lastName", {
              required: "Last name is required",
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Last name can only contain alphabets",
              },
              maxLength: {
                value: 20,
                message: "First name cannot exceed 20 characters",
              },
            })}
          />
          <p className="error">{errors.lastName?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="email" className="block text-gray-700">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            className="w-full border border-gray-300 rounded-md"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: "Invalid email format",
              },
            })}
          />
          <p className="error">{errors.email?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="password" className="block text-gray-700">
            Password <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="password"
            className="w-full border border-gray-300 rounded-md"
            {...register("password", {
              required: "Password is required",
              maxLength: {
                value: 20,
                message: "Password cannot exceed 20 characters",
              },
            })}
          />
          <p className="error">{errors.password?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="orgTypeId" className="block text-gray-700">
            Organization Type <span className="text-red-500">*</span>
          </label>
          <select
            id="orgTypeId"
            className="w-full border border-gray-300 rounded-md"
            {...register("orgTypeId", {
              required: "Organization Type ID is required",
            })}
            defaultValue=""
          >
            <option value="" disabled>
              Select an option
            </option>
            {orgTypes.map((option) => (
              <option key={option.orgTypeId} value={option.orgTypeId}>
                {option.orgType}
              </option>
            ))}
          </select>
          <p className="error">{errors.orgTypeId?.message}</p>
        </div>
        <div className="form-field">
          <label htmlFor="name" className="block text-gray-700">
            Organisation Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            className="w-full border border-gray-300 rounded-md"
            {...register("name", {
              required: 'Organisation name is required',
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: "Organisation name can only contain alphabets",
              },
              maxLength: {
                value: 20,
                message: "Organisation name cannot exceed 20 characters",
              },
            })}
          />
          <p className="error">{errors.name?.message}</p>
        </div>
        <div className="form-field full-width">
          <label htmlFor="phoneNumber1" className="block text-gray-700">
            Contact Number <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="phoneNumber1"
            className="w-full border border-gray-300 rounded-md"
            {...register("phoneNumber1", {
              required: "Phone number is required",
              pattern: {
                value: /^\d{10}$/,
                message: "Phone number must be 10 digits",
              },
              maxLength: {
                value: 10,
                message: "Phone number cannot exceed 10 digits",
              },
            })}
          />
          <p className="error">{errors.phoneNumber1?.message}</p>
        </div>
      </div>
      <button type="submit" className="gradient-button mt-4">
        Signup
      </button>
      {/* {process.env.NODE_ENV === "development" && <DevTool control={control} />} */}
    </form>
  );
}

export default SignupForm;
