import axios from "axios";

const ATS_BASE_URL = process.env.REACT_APP_ATS_BASE_URL;

const api = axios.create({
  baseURL: ATS_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("jwtToken");
    if (accessToken) {
      config.headers["X-Auth-Token"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const formDataapi = axios.create({
  baseURL: ATS_BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const UserLogin = async (credentials) => {
  const response = await api.post(
    `${ATS_BASE_URL}/ats-service/api/v1/user/authenticate`,
    credentials
  );
  return response.data;
};

export const UserSignup = async (userInfo) => {
  const response = await api.post(
    `${ATS_BASE_URL}/ats-service/api/v1/org/signup`,
    userInfo
  );
  return response.data;
};

export const getOrganizationTypes = () => {
  return api.get(`/ats-service/api/v1/org/types`);
};

export const checkEmailExists = (payload) => {
  return api.post(`/ats-service/api/v1/user/exists`, payload);
};

// client management starts from here

export const addManageClient = (client) => {
  return api.post(`ats-service/api/v1/client/add`, client);
};

export const getClients = (orgId) => {
  return api.get(`/ats-service/api/v1/client/info?orgId=${orgId}`);
};

export const updateClient = (clientId, data) => {
  return api.post(`/ats-service/api/v1/client/update/${clientId}`, data);
};

export const deleteClient = (contactId) => {
  return api.delete(`/ats-service/api/v1/client/delete/${contactId}`);
};

// job management starts from here

export const getJobs = (orgId, clientId = null, jobId = null) => {
  return api.get(`/ats-service/api/v1/job/${orgId}/list`);
};

export const addJob = (job) => {
  return api.post(`ats-service/api/v1/job/add`, job);
};

export const updateJob = (job) => {
  return api.post(`ats-service/api/v1/job/update`, job);
};

export const deleteJob = (jobId) => {
  return api.delete(`ats-service/api/v1/job/delete/${jobId}`);
};

export const getSkills = () => {
  return api.get(`/ats-service/api/v1/skills`);
};

export const addSkills = (skill) => {
  return api.post(`/ats-service/api/v1/skills/add`, skill);
};

export const getCities = () => {
  return api.get(`/ats-service/api/v1/locations`);
};

// user management starts from here
export const getUsers = (orgId) => {
  return api.get(`/ats-service/api/v1/user/info?orgId=${orgId}`);
};

export const getUserNames = (orgId) => {
  return api.get(`/ats-service/api/v1/user/usernames?orgId=${orgId}`);
};

export const addUser = (user) => {
  return api.post(`ats-service/api/v1/user/add`, user);
};

export const updateUser = (user) => {
  return api.post(`ats-service/api/v1/user/update`, user);
};

export const deleteUser = (userId) => {
  return api.delete(`ats-service/api/v1/user/delete/${userId}`);
};

// candidate manger starts from here

export const getCandidates = (payload) => {
  return api.post(`/ats-service/api/v1/candidates/all`, payload);
};

export const addCandidate = (candidate) => {
  return api.post(`ats-service/api/v1/candidates/add`, candidate);
};

export const updateCandidate = (candidate) => {
  return api.post(`ats-service/api/v1/candidates/update`, candidate);
};

export const deleteCandidate = (candidateId) => {
  return api.delete(`ats-service/api/v1/candidates/delete/${candidateId}`);
};

export const updateCandidateStatus = (payload) => {
  return api.post(`ats-service/api/v1/candidates/update-status`, payload)
}

export const resetPassword = (user) => {
  return api.post(`ats-service/api/v1/auth/reset-password`, user);
};

export const sendOtp = (data) => {
  return api.post(`ats-service/api/v1/auth/send-otp`, data);
};

export const validateOtp = (data) => {
  return api.post(`ats-service/api/v1/auth/validate-otp`, data);
};

export const mapJobsToUser = (payload) => {
  return api.post(`ats-service/api/v1/job/map-job`, payload);
};

export const uploadJD = (payload) => {
  return formDataapi.post(`ats-service/api/v1/job/upload-jd`, payload);
};

export const uploadResume = (payload) => {
  return formDataapi.post(`ats-service/api/v1/candidate/upload-resume`, payload);
};
