import React, { useState, useEffect } from "react";
import Modal from "../../components/Modal";
import CandidateForm from "./CandidateForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faUserPlus,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  getCandidates,
  addCandidate,
  updateCandidate,
  deleteCandidate,
  uploadResume,
  updateCandidateStatus,
} from "../../Auth/services/Authapi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../../src/components/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { useClients } from "../../contexts/ClientsContext";
import { useJobs } from "../../contexts/JobsContext";

const ManageCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedJob, setSelectedJob] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const clients = useClients();
  const jobs = useJobs();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCandidates();
  }, [selectedClient, selectedJob]);

  const fetchCandidates = async () => {
    try {
      const payload = { orgId: user.orgId };

      if (selectedClient) {
        payload.clientId = selectedClient;
      }

      if (selectedJob) {
        payload.jobId = selectedJob;
      }
      const candidateData = await getCandidates(payload);
      setCandidates(
        Array.isArray(candidateData.data.data) ? candidateData.data.data : []
      );
    } catch (error) {
      toast.error("An error occurred while fetching the candidate data");
      console.error(error);
    }
  };

  const addNewCandidate = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedCandidate(null);
  };

  const openDeleteConfirmation = (candidateId) => {
    setSelectedCandidate(candidateId);
    setIsDeleteModalOpen(true);
  };

  const handleSaveCandidate = async (candidate, resume) => {
    try {
      let response;
      if (candidate.candidateId) {
        response = await updateCandidate(candidate);
        toast.success("Candidate updated successfully");
      } else {
        candidate.orgId = user.orgId;
        response = await addCandidate(candidate);
        toast.success("Candidate added successfully");
      }

      const { jobId, orgId, clientId, candidateId } = response.data.data;

      if (resume) {
        const formData = new FormData();
        formData.append("resume", resume);
        formData.append("orgId", orgId);
        formData.append("jobId", jobId);
        formData.append("clientId", clientId);
        formData.append("candidateId", candidateId);
        await uploadResume(formData);
        toast.success("Resume uploaded successfully");
      }
    } catch (error) {
      toast.error("An error occurred while saving the candidate data");
    }
    fetchCandidates();
    handleCloseModal();
  };

  const handleEdit = (candidate) => {
    setIsModalOpen(true);
    setSelectedCandidate(candidate);
  };

  const handleDelete = async (candidateId) => {
    try {
      await deleteCandidate(candidateId);
      const updatedCandidates = candidates.filter(
        (candidate) => candidate.candidateId !== candidateId
      );
      setCandidates(updatedCandidates);
      toast.success("Candidate deleted successfully");
    } catch (error) {
      toast.error("An error occurred while deleting the candidate");
    }
  };

  const toggleCandidateSelection = (candidateId) => {
    setSelectedCandidates((prevSelected) =>
      prevSelected.includes(candidateId)
        ? prevSelected.filter((id) => id !== candidateId)
        : [...prevSelected, candidateId]
    );
  };

  const handleShareCandidates = async () => {
      if (!selectedClient) {
        toast.error("Please select a client before sharing candidates.");
        return;
      }

      if (!selectedJob) {
        toast.error("Please select a job before sharing candidates.");
        return;
      }
    if (selectedCandidates.length > 0) {
      const payload = selectedCandidates.map((candidate) => ({
        orgId: user.orgId,
        candidateId: candidate,
        clientId: Number(selectedClient),
        jobId: Number(selectedJob),
        status: 'Shared to Client'
      }))

      try {
        const response = await updateCandidateStatus(payload);
        console.log(response)
        if (response.data.statusCode === 200) {
          toast.success("Candidates shared successfully");
          setSelectedClient("");
          setSelectedJob("");
          setSelectedCandidates([]);
          setIsAllSelected(false);
        } else {
          toast.error("An error occured while sharing the candidate");
        }
      } catch (error) {
        toast.error('An error occured while sharing the candidates')
      }
    } else {
      toast.error("Please select at least one candidate to share.");
    }
  };

  const toggleSelectAll = (e) => {
    const checked = e.target.checked;
    setIsAllSelected(checked);

    if (checked) {
      setSelectedCandidates(
        candidates.map((candidate) => candidate.candidateId)
      );
    } else {
      setSelectedCandidates([]);
    }
  };

  return (
    <div className="m-2">
      <div className="flex justify-between items-center mb-4">
        <div className="font-bold text-xl ml-3">All Candidates</div>
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4">
          {/* Client Selection Dropdown */}
          <select
            value={selectedClient}
            onChange={(e) => setSelectedClient(e.target.value)}
            className="w-40 p-2 border rounded-md"
          >
            <option value="">Select Client</option>
            {clients.map((client) => (
              <option key={client.clientId} value={client.clientId}>
                {client.clientName}
              </option>
            ))}
          </select>

          {/* Job Selection Dropdown */}
          <select
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
            className="w-40 p-2 border rounded-md"
            disabled={!selectedClient}
          >
            <option value="">Select Job</option>
            {jobs
              .filter((job) => Number(job.clientId) === Number(selectedClient))
              .map((job) => (
                <option key={job.jobId} value={job.jobId}>
                  {job.jobTitle}
                </option>
              ))}
          </select>
        </div>

        {/* Action Buttons */}
        <div className="flex space-x-2">
          <button
            onClick={addNewCandidate}
            className="px-3 py-2 bg-purple-600 hover:bg-purple-500 text-white rounded-md text-sm"
          >
            <FontAwesomeIcon icon={faUserPlus} className="mr-2" />
            Add Candidate
          </button>

          {selectedCandidates.length > 0 && (
            <button
              onClick={handleShareCandidates}
              className="px-3 py-2 bg-green-600 hover:bg-green-500 text-white rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faShareSquare} className="mr-2" />
              Share
            </button>
          )}
        </div>
      </div>

      {/* Candidates Table */}
      <table className="table-auto w-full border-collapse mt-5">
        <thead>
          <tr className="table-header-bg">
            <th className="px-4 py-2">
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={toggleSelectAll}
              />
            </th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Phone</th>
            <th className="px-4 py-2">Notice Period</th>
            <th className="px-4 py-2">Current CTC</th>
            <th className="px-4 py-2">Resume</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {candidates.length === 0 ? (
            <tr>
              <td colSpan="8" className="text-center py-2 no-records">
                No candidates are available
              </td>
            </tr>
          ) : (
            candidates.map((candidate) => (
              <tr key={candidate.candidateId} className="border-b">
                <td className="px-4 py-2 text-center">
                  <input
                    type="checkbox"
                    checked={selectedCandidates.includes(candidate.candidateId)}
                    onChange={() =>
                      toggleCandidateSelection(candidate.candidateId)
                    }
                  />
                </td>
                <td className="px-4 py-2 text-center">
                  {candidate.candidateFullName}
                </td>
                <td className="px-4 py-2 text-center">{candidate.emailId}</td>
                <td className="px-4 py-2 text-center">{candidate.mobileNo}</td>
                <td className="px-4 py-2 text-center">
                  {candidate.noticePeriod}
                </td>
                <td className="px-4 py-2 text-center">
                  {candidate.currentCtc}
                </td>
                <td className="px-4 py-2 text-center">
                  <a
                    href={candidate.resumeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500"
                  >
                    View
                  </a>{" "}
                  |{" "}
                  <a
                    href={candidate.resumeUrl}
                    download
                    className="text-blue-500"
                  >
                    Download
                  </a>
                </td>
                <td className="px-4 py-2 text-center flex justify-center">
                  <div className="icon-buttons">
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        color: "#00bfff",
                      }}
                      onClick={() => handleEdit(candidate)}
                    />
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px",
                        color: "red",
                      }}
                      onClick={() =>
                        openDeleteConfirmation(candidate.candidateId)
                      }
                    />
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <Modal
        isOpen={isModalOpen}
        header={
          <h1>{selectedCandidate ? "Update Candidate" : "Add Candidate"}</h1>
        }
        onRequestClose={handleCloseModal}
      >
        <CandidateForm
          onClose={handleCloseModal}
          onSave={handleSaveCandidate}
          initialData={selectedCandidate}
          userData={user}
          isEditMode={!!selectedCandidate}
        />
      </Modal>

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={() => handleDelete(selectedCandidate)}
        message={`Are you sure you want to delete ${selectedCandidate?.candidateFullName} ?`}
      />
    </div>
  );
};

export default ManageCandidates;
