import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCandidateById } from "../../Auth/services/Authapi";

const ViewCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const { ids } = useParams();

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const candidateIds = ids.split(",");
        // const fetchedCandidates = await Promise.all(
        //   candidateIds.map((id) => getCandidateById(id))
        // );
        // setCandidates(fetchedCandidates.map((res) => res.data.data));
      } catch (error) {
        console.error("Error fetching candidates:", error);
      }
    };

    fetchCandidates();
  }, [ids]);

  return (
    <div>
      <h1>Selected Candidates</h1>
      <ul>
        {candidates.map((candidate) => (
          <li key={candidate.candidateId}>
            <strong>{candidate.candidateFullName}</strong>
            <p>Email: {candidate.emailId}</p>
            <p>Phone: {candidate.mobileNo}</p>
            <p>Notice Period: {candidate.noticePeriod}</p>
            <p>Current CTC: {candidate.currentCtc}</p>
            <a
              href={candidate.resumeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Resume
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViewCandidates;
