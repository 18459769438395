import React, {Suspense, lazy} from "react";
import ManageUsers from "./pages/UserManager/ManageUsers";
import ManageCandidates from "./pages/CandidateManager/ManageCandidates";
import ForgotPassword from "./pages/ForgotPassword";
import MapUser from "./pages/MapUser/MapUser";
import ViewCandidates from "./pages/ViewCandidates/ViewCandidates";
const LandingPage = lazy(() => import("./Auth/pages/LandingPage"));
const Signup = lazy(() => import("./Auth/pages/Signup"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const ViewJobs = lazy(() => import("./pages/ClientManager/ViewJobs"));
const SignupLoginPage = lazy(() => import("./Auth/pages/SignupLoginPage"));
const AddManageClients = lazy(() =>
  import("./pages/ClientManager/AddManageClients")
);

const Loading = () => <div>Loading...</div>;


export const appRoutes = [
  {
    path: "/login",
    element: (
      <Suspense fallback={<Loading />}>
        <SignupLoginPage />
      </Suspense>
    ),
  },
  {
    path: "/view-candidates",
    element: (<Suspense fallback={<Loading />}>
      <ViewCandidates/>
    </Suspense>)
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<Loading />}>
        <SignupLoginPage />
      </Suspense>
    ),
  },
  {
    path: "/signup",
    element: (
      <Suspense fallback={<Loading />}>
        <Signup />
      </Suspense>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <Suspense fallback={<Loading />}>
        <ForgotPassword />
      </Suspense>
    ),
  },
  {
    path: "/landing-page",
    element: (
      <Suspense fallback={<Loading />}>
        <LandingPage />
      </Suspense>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <Suspense fallback={<Loading />}>
            <Dashboard />
          </Suspense>
        ),
      },
      {
        path: "manage-clients",
        element: (
          <Suspense fallback={<Loading />}>
            <AddManageClients />
          </Suspense>
        ),
      },
      {
        path: "view-jobs",
        element: (
          <Suspense fallback={<Loading />}>
            <ViewJobs />
          </Suspense>
        ),
      },
      {
        path: "manage-users",
        element: (
          <Suspense fallback={<Loading />}>
            <ManageUsers />
          </Suspense>
        ),
      },
      {
        path: "map-user",
        element: (
          <Suspense fallback={<Loading />}>
            <MapUser />
          </Suspense>
        ),
      },
      {
        path: "manage-candidates",
        element: (
          <Suspense fallback={<Loading />}>
            <ManageCandidates />
          </Suspense>
        ),
      },
    ],
  },
];

