import React, { useEffect, useState } from "react";
import "../components/Modal.css";

const Modal = ({ isOpen, onClose, children, header, footer}) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };


  return (
    <>
      {isVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div
            className="bg-white rounded-lg p-8 flex flex-col"
            style={{
              width: "80%",
              maxWidth: "800px",
              maxWidth: "800px",
              maxHeight: "80%",
              overflowY: "auto",
            }}
          >
            {header && (
              <div className="mb-4 text-2xl font-semibold">{header}</div>
            )}
            <div>{children}</div>
            {footer && (
              <div className="flex justify-center mt-6">
                <button
                  onClick={handleClose}
                  className="text-gray-700 border border-gray-800 px-4 py-1 rounded hover:bg-gray-300 focus:outline-none focus:bg-gray-300"
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
